.home {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1120px;
}

.home__row {
  display: flex;
  z-index: 1;
  margin-left: 5px;
  margin-right: 5px;
}

.home__image {
  width: 100%;
  z-index: -1;
  margin-bottom: -150px;
}
