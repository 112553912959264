.header {
  height: 60px;
  display: flex;
  align-items: center;
  background-color: white;
  position: sticky;
  width: 100%;
  justify-content: space-between;
  margin-left: auto;
  margin-right: auto;
  max-width: 1120px;
}

.header__border {
  display: flex;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  width: 100%;
}

.header__logo {
  width: 100px;
  object-fit: contain;
  margin-left: 10px;
  margin-top: 10px;
}

.header__optionLineOne {
  font-size: 14px;
}

.header__optionLineTwo {
  font-size: 16px;
  font-weight: 800;
}

.header__nav {
  display: flex;
  justify-content: space-evenly;
}

.header__option {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
  text-align: end;
}
