.login {
  background-color: white;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login__logo {
  margin-top: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  width: 150px;
  margin-right: auto;
  margin-left: auto;
}

.login__container {
  width: 300px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid lightgray;
  padding: 20px;
}

.login__container > h1 {
  font-weight: 500;
  margin-bottom: 20px;
}

.login__container > form > h5 {
  font-weight: 500;
  margin-bottom: 5px;
}

.login__container > form > input {
  height: 30px;
  margin-bottom: 10px;
  background-color: white;
  width: 98%;
}

.login__container > p {
  margin-top: 15px;
  font-size: 12px;
}

.login__container > h5 {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid black;
  line-height: 0.1em;
  margin: 20px 0 20px;
  font-weight: 500;
}

.login__container > h5 > span {
  background: #fff;
  padding: 0 10px;
}

.login__signInButton {
  background: black;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  color: white;
  border-color: black;
}

.login__signInButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.login__signInButton span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.login__signInButton:hover span {
  padding-right: 25px;
}

.login__signInButton:hover span:after {
  opacity: 1;
  right: 0;
}

.login__registerButton {
  background: lightgrey;
  border-radius: 2px;
  width: 100%;
  height: 30px;
  border: 1px solid;
  margin-top: 10px;
  border-color: grey;
}

.login__registerButton span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.login__registerButton span:after {
  content: "\00bb";
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.login__registerButton:hover span {
  padding-right: 25px;
}

.login__registerButton:hover span:after {
  opacity: 1;
  right: 0;
}
